'use client';

import React, { useCallback, useEffect } from 'react';
import { Divider, Flex, Pagination, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@mezzoforte/forge';
import { parseAsInteger, parseAsString, useQueryStates } from 'nuqs';
import { Box } from '@chakra-ui/react';
import {
  useListFilterParams,
  paramsTypeToQueryType,
  querySortToSortTab,
  queryTypeToParamsType,
} from '@/hooks/useListFilterParams';
import { EntryListFilterType, EntryListQuery, ListFilterOdometer, useEntryList } from '@/hooks/useEntryList';
import { EntryListFilter, EntryListFilterValues } from '@/components/ListPage/EntryListFilter';
import { EntryList } from '@/components/EntryList/EntryList';
import { FavoritesList } from '@/components/Favorites/FavoritesList';
import { entryListItemToListEntry } from '@/util/entry';
import { useCategories } from '@/hooks/useCategories';
import { ListAnalytics } from '@/types/ListEntry';
import { AddSavedSearch } from '@/components/ListPage/AddSavedSearch';
import { PrismicBannerItem } from '@/components/Banners/EntryListBanner';
import { EntryListAnalytics } from '@/features/EntryList/EntryListAnalytics';
import { VideolyPagePlaceholder } from '@/features/Videoly/VideolyPagePlaceholder';

function shouldShowSavedSearchButton(query: EntryListQuery) {
  return query.filter?.searchTerm !== undefined;
}

interface EntryListFilterAndResultsProps {
  categoryId?: number;
  baseQuery?: Partial<EntryListQuery>;
  banners?: PrismicBannerItem[];
  seoText?: string;
  analytics: ListAnalytics;
  title?: string;
}

export function EntryListFilterAndResults({
  categoryId,
  baseQuery,
  banners,
  seoText,
  analytics,
  title,
}: EntryListFilterAndResultsProps) {
  const [queryFilter, setQueryFilter] = useQueryStates({
    region: parseAsString,
    tyyppi: {
      parse: (value) => paramsTypeToQueryType[value] ?? null,
      serialize: (value: EntryListFilterType) => queryTypeToParamsType[value],
    },
    met: {
      parse: (value) => value === '1',
      serialize: (value) => (value ? '1' : '0'),
    },
    paattyneet: {
      parse: (value) => value === '1',
      serialize: (value) => (value ? '1' : '0'),
    },
    merkki: parseAsString,
    vetotapa: parseAsInteger,
    km: {
      parse: (value) => (/^\d+$/.test(value) || value === 'MAX' ? (value as ListFilterOdometer) : null),
      serialize: (value) => String(value),
    },
    vaihteisto: parseAsInteger,
    polttoaine: parseAsInteger,
    vuosimalliMin: parseAsInteger,
    vuosimalliMax: parseAsInteger,
  });

  const { query, setQueryPage, onSortChange, onPageChange } = useListFilterParams({
    ...baseQuery?.filter,
    category: categoryId,
    region: queryFilter.region ?? undefined,
    type: queryFilter.tyyppi ?? undefined,
    soldForHighestBidder: queryFilter.met ?? undefined,
    onlyEnded: queryFilter.paattyneet ?? undefined,
    metadataManufacturer: queryFilter.merkki ?? undefined,
    metadataDrive: queryFilter.vetotapa ?? undefined,
    metadataOdometer: queryFilter.km ?? undefined,
    metadataTransmission: queryFilter.vaihteisto ?? undefined,
    metadataFuelType: queryFilter.polttoaine ?? undefined,
    metadataYearModelStart: queryFilter.vuosimalliMin ?? undefined,
    metadataYearModelEnd: queryFilter.vuosimalliMax ?? undefined,
  });

  const { status, data } = useEntryList(query);
  const { data: categoriesData } = useCategories();

  useEffect(() => {
    if (status !== 'success') {
      return;
    }

    if (data.redirect && data.redirectUrl !== null) {
      location.href = data.redirectUrl;
    }
  }, [status, data]);

  const onChangeFilter = useCallback(
    (values: EntryListFilterValues) => {
      const parseSelect = (v: string | undefined) => (v !== undefined && v !== '' ? v : null);
      const parseSelectAsNumber = (v: string | undefined) => (v !== undefined && v !== '' ? Number(v) : null);

      void setQueryFilter({
        region: values.region !== '' ? values.region : null,
        tyyppi: values.type !== EntryListFilterType.UsedAndNew ? values.type : null,
        met: values.soldForHighestBidder ? true : null,
        paattyneet: values.onlyEnded ? true : null,
        merkki: parseSelect(values.metadataManufacturer),
        vetotapa: parseSelectAsNumber(values.metadataDrive),
        km: parseSelect(values.metadataOdometer) as ListFilterOdometer,
        vaihteisto: parseSelectAsNumber(values.metadataTransmission),
        polttoaine: parseSelectAsNumber(values.metadataFuelType),
        vuosimalliMin: parseSelectAsNumber(values.metadataYearModelStart),
        vuosimalliMax: parseSelectAsNumber(values.metadataYearModelEnd),
      });
      void setQueryPage(1);
    },
    [setQueryFilter, setQueryPage]
  );

  const entries = status === 'success' ? data.entries.map(entryListItemToListEntry) : undefined;

  const itemsPerPage = query.limit ?? 40;
  const startIndex = ((query.page ?? 1) - 1) * itemsPerPage;
  const bannersForPage = banners
    ?.map((banner) => ({ ...banner, order: (banner.order ?? 0) - startIndex }))
    ?.filter((banner) => banner.order >= 0 && banner.order < itemsPerPage);

  const flatCategories = (categoriesData?.categories ?? []).flatMap((c) => [c, ...Object.values(c.children)]);
  const category = flatCategories.find((c) => c.id === categoryId);
  const parentCategory = flatCategories.find((c) => c.id === category?.parentId);

  return (
    <>
      <EntryListAnalytics
        analytics={analytics}
        query={query}
        data={data}
        custom={{
          parent_category: parentCategory?.name,
          parent_category_id: parentCategory?.id,
          category: category?.name,
          category_id: category?.id,
        }}
      />

      <EntryListFilter
        categoryId={categoryId}
        defaultValues={{
          soldForHighestBidder: query.filter.soldForHighestBidder,
          onlyEnded: query.filter.onlyEnded,
          region: query.filter.region,
          type: query.filter.type,
          metadataManufacturer: query.filter.metadataManufacturer,
          metadataDrive: query.filter.metadataDrive?.toString(),
          metadataOdometer: query.filter.metadataOdometer,
          metadataTransmission: query.filter.metadataTransmission?.toString(),
          metadataFuelType: query.filter.metadataFuelType?.toString(),
          metadataYearModelStart: query.filter.metadataYearModelStart?.toString(),
          metadataYearModelEnd: query.filter.metadataYearModelEnd?.toString(),
        }}
        onSubmit={onChangeFilter}
      />

      {shouldShowSavedSearchButton(query) && (
        <Box my={4}>
          <AddSavedSearch query={query} />
        </Box>
      )}

      <Divider my={5} />

      <Flex
        mb={5}
        direction={{ base: 'column', md: 'row-reverse' }}
        align={{ base: 'start', md: 'center' }}
        justify="space-between"
        gap={3}
      >
        {/* TODO: Rewrite tabs in Forge, Chakra's tabs' defaultIndex does not support SSR properly. */}
        <Tabs
          defaultIndex={Number(querySortToSortTab[query.sort] ?? 0)}
          onChange={onSortChange}
          sx={{
            '> div': {
              borderBottom: 'none',
            },
          }}
        >
          <TabList>
            <Tab>Päättyvät</Tab>
            <Tab>Kiinnostavimmat</Tab>
            <Tab>Uusimmat</Tab>
          </TabList>
          <TabPanels display="none">
            <TabPanel />
            <TabPanel />
            <TabPanel />
          </TabPanels>
        </Tabs>
        <Text>
          {status === 'success' ? `${data.pages.totalItemCount} ilmoitusta, sivu ${data.pages.current}` : 'Ladataan...'}
        </Text>
      </Flex>

      <VideolyPagePlaceholder title={title ?? ''} />

      <Flex>
        <EntryList
          flexGrow={1}
          entries={entries}
          banners={bannersForPage}
          dummyEntryCount={itemsPerPage}
          analytics={analytics}
        />
        <FavoritesList ml={3} />
      </Flex>

      <Flex mt={5} mb={6} align="center" justify="center">
        <Pagination
          total={status === 'success' ? data.pages.pageCount : 100}
          current={query.page ?? 1}
          onChange={onPageChange}
        />
      </Flex>

      {seoText !== undefined && (
        <Text fontSize="sm" mt={5} mb={6}>
          {seoText}
        </Text>
      )}
    </>
  );
}
